import React from 'react'
import { Button } from 'react-bootstrap';

var canv,ctx,px,py,gs,tc,ax,ay,trail,tail;

px=py=10;
gs=tc=20;
ax=ay=15;
trail=[];
tail=5;

class Snek extends React.Component {
  constructor() {
    super();
    this.state = {
      xv: 0,
      yv: 0
    }
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleGameButtonPress = this.handleGameButtonPress.bind(this);
    this.game = this.game.bind(this);
  }

  handleKeyDown(evt) {
    switch(evt.keyCode) {
      case 37:
        this.setState({xv:-1, yv:0});
        evt.preventDefault();
        break;
      case 38:
        this.setState({xv:0, yv:-1});
        evt.preventDefault();
        break;
      case 39:
        this.setState({xv:1, yv:0});
        evt.preventDefault();
        break;
      case 40:
        this.setState({xv:0, yv:1});
        evt.preventDefault();
        break;
      default:
        break;
    }
  }
  handleGameButtonPress(sDirection) {
    switch(sDirection) {
      case 'left':
        this.setState({xv:-1, yv:0});
        break;
      case 'up':
        this.setState({xv:0, yv:-1});
        break;
      case 'right':
        this.setState({xv:1, yv:0});
        break;
      case 'down':
        this.setState({xv:0, yv:1});
        break;
        default:
          break;
    }
  }
  game() {
    px+=this.state.xv;
    py+=this.state.yv;
    if(px<0){
      px=tc-1;
    }
    if(px>tc-1){
      px=0;
    }
    if(py<0){
      py=tc-1;
    }
    if(py>tc-1){
      py=0;
    }
    ctx.fillStyle="black";
    ctx.fillRect(0,0,canv.width,canv.height);

    ctx.fillStyle="lime";
    for(var i=0;i<trail.length;i++){
      ctx.fillRect(trail[i].x*gs,trail[i].y*gs,gs-2,gs-2);
      if(trail[i].x===px && trail[i].y===py){
        tail=5;
      }
    }
    trail.push({x:px,y:py});
    while(trail.length>tail) {
    trail.shift();
    }
    if(ax===px && ay===py){
      tail++;
      ax=Math.floor(Math.random()*tc);
      ay=Math.floor(Math.random()*tc);
    }

    ctx.fillStyle="red";
    ctx.fillRect(ax*gs,ay*gs,gs-2,gs-2);

  }

  render () {
    return (
      <div role="tab" tabIndex={0} onKeyDown={this.handleKeyDown}>
        <header className="masthead about">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>Snek!</h1>
                  <span className="subheading">Gimme a SNEK easter egg!</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-md-3 mx-auto">
            </div>
            <div className="col-md-6 mx-auto">
              <div className="text-center">
                <canvas id="gc" width="400" height="400"/>
                <br/>
                <Button className="btn btn-secondary touchTargetOnly" onClick={() => this.handleGameButtonPress('up')}>^</Button>
                <br/>
                <Button className="btn btn-secondary touchTargetOnly" onClick={() => this.handleGameButtonPress('left')}>{'<'}</Button>
                <Button className="btn btn-secondary touchTargetOnly" onClick={() => this.handleGameButtonPress('right')}>{'>'}</Button>
                <br/>
                <Button className="btn btn-secondary touchTargetOnly" onClick={() => this.handleGameButtonPress('down')}>v</Button>
                <p>Game by Adam Frewin</p>
                <p>React-ified by Chris Frewin</p>
              </div>
            </div>
            <div className="col-md-3 mx-auto">
            </div>
        </div>
      </div>
    </div>

    );
  }
  componentDidMount() {
    canv=document.getElementById("gc");
    ctx=canv.getContext("2d");
    document.addEventListener("keydown",this.handleKeyDown);
    setInterval(this.game,1000/15);
  }
}

export default Snek;
